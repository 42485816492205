import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import flagsApi from "api/flags-api";

export const Feature = ({ children }) => {
  const [flag] = useState(false);

  if (flag) {
    return children;
  }

  return null;
};

export const ApiFeatureFlagged = ({ children, featureName }) => {
  const { isLoading, isError, data } = useQuery(["feature-flags"], () => flagsApi.getFlags());

  if (isLoading || isError) {
    return null;
  }
  let shouldRender = false;
  for (const flag of data) {
    const { feature_name: name, enabled } = flag;
    if (name === featureName && enabled) {
      shouldRender = true;
      break;
    }
  }
  if (shouldRender) {
    return children;
  }
  return null;
};
